<template>
  <div class="preview">
    <NuxtLink :to="'/' + article.full_slug">
      <div class="preview__image-wrapper">
        <BaseNuxtImage
          :src="imgSrc"
          :alt="imgTitle"
          loading="lazy"
          class="preview__image"
        />
      </div>
    </NuxtLink>
    <NuxtLink :to="'/' + article.full_slug" class="preview__link">
      <component :is="titleTag" class="preview__title heading--4">
        {{ article.content.meta.title }}
      </component>
    </NuxtLink>
    <p class="preview__description">
      {{ article.content.meta.description }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import type { Story } from '@/types'

interface Props {
  article: Story<any>
  hideAuthor?: boolean
}

const props = defineProps<Props>()
const currentPath = useRoute().fullPath

const imgSrc = computed(() => {
  return props.article.content?.meta.og_image
})
const imgTitle = computed(() => {
  return props.article.content?.meta.og_title !== '' ||
    props.article.content?.meta.og_title !== undefined
    ? props.article.content.meta.og_title
    : 'preview image'
})

const titleTag = computed(() => {
  const pattern = /\/blog\/articles\/.+/
  return pattern.test(currentPath) ? 'p' : 'h2'
})
</script>

<style lang="scss" scoped>
.preview {
  &__image-wrapper {
    position: relative;
    width: 100%;
    padding-top: 55.2%;
    margin-bottom: 32px;
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }

  &__author {
    margin-bottom: 14px;
  }

  &__link {
    text-decoration: none;
    color: var(--color-text);

    &:hover > * {
      color: var(--color-link);
    }
  }

  &__title {
    margin-bottom: 10px;
  }

  &__description {
    margin-bottom: 0;
  }
}
</style>
